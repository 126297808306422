import { loadAndApplyTextureToMaterial } from './textureManager.js';
import materials from './materials.js';

export async function initMaterials(parent, materialName, texturePath = null) {
  parent.traverse(async (o) => {
    if (o.isMesh && o.name.includes(materialName)) {
      if (o.name.includes('reinforcements') || o.name.includes('ripstop')) {
        return;
      }

      const material = materials[materialName];
      if (material) {
        // Dispose of the previous material and texture before reassigning
        if (o.material && o.material.dispose) {
          o.material.dispose();
        }

        if (texturePath) {
          loadAndApplyTextureToMaterial(material, texturePath);
        }

        o.appliedTexture = material.map;
        o.originalMaterial = o.material;
        o.material = material;
        o.nameID = materialName;

        o.addEventListener('click', () => {
          if (texturePath) {
            loadAndApplyTextureToMaterial(o.material, texturePath);
          }
        });
      } else {
        console.warn(`Material not found for ${materialName}.`);
      }
    }
  });
}

export function disposeMaterials(parent) {
  parent.traverse((o) => {
    if (o.isMesh && o.originalMaterial) {
      // Dispose of the material
      if (o.material && o.material.dispose) {
        o.material.dispose();
      }
      // Restore the original material
      o.material = o.originalMaterial;
      delete o.originalMaterial;
    }
  });
}
